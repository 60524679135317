import { tokens } from '@/styles/tokens';
import { FlexProps, Flex, Link } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { ReactNode, useMemo } from 'react';

interface NavItemProps extends FlexProps {
  icon: (_color: string) => ReactNode;
  path: string;
  children: ReactNode;
  subitems?: {
    name: string,
    path: string,
  }[];
}
export const NavItem = ({ icon, path, children, subitems }: NavItemProps) => {
  const pathname = usePathname();

  const isNavItemSelected = useMemo(() => {
    return pathname.includes(path);
  }, [pathname]);

  const isSubRouteSelected = (route: string) => {
    return pathname.includes(path + route);
  };

  return (
    <>
      <Link href={path} style={{ textDecoration: 'none' }}>
        <Flex
          alignItems="center"
          padding={4}
          marginX={4}
          borderRadius="lg"
          role="group"
          cursor="pointer"
          color={
            isNavItemSelected ? tokens.text.white.color : tokens.text.gray.color
          }
          backgroundColor={
            isNavItemSelected ? tokens.background.primary.color : undefined
          }
        >
          {icon(
            isNavItemSelected ? tokens.text.white.color : tokens.text.gray.color
          )}
          {children}
        </Flex>
      </Link>
      {subitems?.length ? (
        <Flex flexDirection={'column'} alignItems={'center'}>
          {subitems.map((item) => (
            <Link
              href={path + item.path}
              style={{ textDecoration: 'none' }}
              key={path + item.path}
            >
              <Flex
                alignItems="center"
                padding={4}
                marginX={4}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color={
                  isSubRouteSelected(item.path)
                    ? tokens.text.primaryLight.color
                    : tokens.text.gray.color
                }
              >
                {item.name}
              </Flex>
            </Link>
          ))}
        </Flex>
      ) : null}
    </>
  );
};
