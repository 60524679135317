import { Icon } from '@chakra-ui/react';

  
export default function Doctors(props) {
  return (
    <Icon {...props} viewBox="0 0 21 25" >
      <path d="M18.9 4.16667H2.1C0.945 4.16667 0 5.10417 0 6.25V18.75C0 19.8958 0.945 20.8333 2.1 20.8333H18.9C20.055 20.8333 21 19.8958 21 18.75V6.25C21 5.10417 20.055 4.16667 18.9 4.16667ZM18.9 18.75H2.1V6.25H18.9V18.75ZM2.1 0H18.9V2.08333H2.1V0ZM2.1 22.9167H18.9V25H2.1V22.9167ZM10.5 12.5C11.949 12.5 13.125 11.3333 13.125 9.89583C13.125 8.45833 11.949 7.29167 10.5 7.29167C9.051 7.29167 7.875 8.45833 7.875 9.89583C7.875 11.3333 9.051 12.5 10.5 12.5ZM10.5 8.85417C11.0775 8.85417 11.55 9.32292 11.55 9.89583C11.55 10.4688 11.0775 10.9375 10.5 10.9375C9.9225 10.9375 9.45 10.4688 9.45 9.89583C9.45 9.32292 9.9225 8.85417 10.5 8.85417ZM15.75 16.6562C15.75 14.4792 12.2745 13.5417 10.5 13.5417C8.7255 13.5417 5.25 14.4792 5.25 16.6562V17.7083H15.75V16.6562ZM7.1505 16.1458C7.791 15.6042 9.282 15.1042 10.5 15.1042C11.7285 15.1042 13.2195 15.6042 13.86 16.1458H7.1505Z" fill="currentColor"/>
    </Icon>
  );
}
