import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { onboardingAPIV2Client } from '../../api';
import { FeatureFlags } from '@medsimples/doctor-onboarding-openapi-v2';

const FeatureFlagContext = createContext<FeatureFlags>({});

const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [flags, setFlags] = useState({});

  useEffect(() => {
    onboardingAPIV2Client.admin.listFeatureFlags().then(res => {
      setFlags(res.data);
    });
  }, []);

  
  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;

export const useFlags = () => useContext(FeatureFlagContext);
