import { HamburgerIcon } from '@chakra-ui/icons';
import {
  FlexProps,
  Flex,
  IconButton,
  Box,
  HStack,
  Menu,
  MenuButton,
  Avatar,
  VStack,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { Session } from '@ory/client';
import Image from 'next/image';
import { useMemo } from 'react';
import { useAuth } from '../../providers/auth_provider';

interface UserInfoProps extends FlexProps {
  onOpen: () => void;
}
export const UserInfo = ({ onOpen }: UserInfoProps) => {
  const auth = useAuth();
  const userName = useMemo(() => {
    return auth.user?.identity?.traits?.raw_claims?.name &&  auth.user?.identity?.traits?.raw_claims?.name.split(' ').length > 1 ?
      auth.user?.identity?.traits?.raw_claims?.name.split(' ').shift() + ' ' + auth.user?.identity?.traits?.raw_claims?.name.split(' ').pop() :
      auth.user?.identity?.traits?.raw_claims?.name; 
  }, [auth.user?.identity?.traits?.raw_claims?.name ]);

  const logout = () => {
    window.location.href = auth.logoutUrl;
  };

  return (
    <Flex
      paddingX={{ base: 4, md: 4 }}
      height={20}
      alignItems="center"
      background="white"
      justifyContent={'space-between'}
    >
      <>
        <Box
          minWidth={{ base: '100px', md: '240px' }}
        >
          <IconButton
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<HamburgerIcon />}
          />
        </Box>

        <Box position={'absolute'} left={'50%'} transform={'translateX(-50%)'}>
          <Image 
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/doctor-onboarding/medsimples-logo.svg`} 
            alt='logo' 
            width={200} 
            height={200} 
          />
        </Box>

        <HStack spacing={{ base: '0', md: '6' }}>
          {userName ? (
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  paddingY={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}
                >
                  <HStack>
                    <Avatar size={'md'} marginRight={2} />
                    <VStack
                      display={{ base: 'none', md: 'flex' }}
                      alignItems="flex-start"
                      spacing={0}
                      marginLeft={2}
                    >
                      <Text fontSize="sm" fontWeight={500}>
                        Bem vindo
                      </Text>
                      <Text fontSize="xs" color="gray.600">
                        {userName}
                      </Text>
                    </VStack>
                  </HStack>
                </MenuButton>
                <MenuList bg="white">
                  <MenuItem onClick={logout}>Sair</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ) : null }
        </HStack>
      </>
    </Flex>
  );
};
