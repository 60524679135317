'use client';

import { theme } from '@/styles/theme';
import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider } from '@chakra-ui/react';
import { type ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/locale/pt_BR';
import AuthProvider from './providers/auth_provider';
import FeatureFlagProvider from './providers/feature_flag_provider';

export function Providers({
  children
}: {
  children: ReactNode,
}) {
  return (
    <CacheProvider>
      <ChakraProvider theme={theme}>
        <ConfigProvider locale={ptBR}>
          <AuthProvider>
            <FeatureFlagProvider>
              { children }
            </FeatureFlagProvider>
          </AuthProvider>
        </ConfigProvider>
      </ChakraProvider>
    </CacheProvider>
  );
}
