import { BoxProps, Button, Flex, Text } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { NavItem } from './navitem';
import Doctors from '@public/icons/doctors/doctors';
import { SearchIcon } from '@chakra-ui/icons';
import { FileTextOutlined } from '@medsimples/design-system/src/antd-icons';
import { useAuth } from '../../providers/auth_provider';
import { useFlags } from '../../providers/feature_flag_provider';
import { PageRoutes } from '../../../routes';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface Link {
  name: string;
  path: string;
  permissions: string[];
  features?: string[];
}

interface LinkItemProps extends Link {
  icon?: (color: string) => ReactNode;
  redirectTo?: string;
  subitems?: Link[];
}

export const Sidebar = ({ onClose, ...rest }: SidebarProps) => {
  const auth = useAuth();
  const flags = useFlags();
  const userPermissions = Object.keys((auth.user.identity.metadata_public as any).permissions);

  const LinkItems: Array<LinkItemProps> = useMemo(() => [
    {
      path: '/doctors',
      ...PageRoutes['/doctors'],
      icon: (color: string) => <Doctors boxSize={5} color={color} />,
    },
    {
      path: '/search',
      ...PageRoutes['/search'],
      icon: (color: string) => <SearchIcon boxSize={5} color={color} />,
      subitems: [
        {
          path: '/business-partner',
          ...PageRoutes['/search/business-partner'],
        }
      ]
    },
    {
      path: '/contract',
      ...PageRoutes['/contract'],
      icon: (color: string) => <FileTextOutlined style={{ fontSize: '1.5em' }} color={color} />,
      subitems: [
        {
          path: '/',
          ...PageRoutes['/contract'],
          name: 'Lista de contratos',
        },
        {
          path: '/reviewer',
          ...PageRoutes['/contract/reviewer'],
        },
      ]
    }
  ], []);

  const checkPermissions = (permissions: string[]) => permissions.every(p => userPermissions.includes(p));
  const checkFeatures = (features?: string[]) => !features || features.every(f => flags[f]);
  const enabledLinkItems = LinkItems.reduce((enabled, curr) => {
    if (checkPermissions(curr.permissions) && checkFeatures(curr.features)) {
      enabled.push({
        ...curr,
        subitems: curr.subitems?.filter(s => checkPermissions(s.permissions) && checkFeatures(s.features))
      });
    }
    return enabled;
  }, [] as LinkItemProps[]);

  return (
    <Flex
      flexDirection={'column'}
      transition="3s ease"
      background="white"
      width="full"
      position="fixed"
      height="full"
      paddingY={5}
      {...rest}
    >
      {enabledLinkItems.map((link) => (
        <NavItem
          key={link.name}
          path={link.path}
          icon={(color: string) => link.icon(color)}
          subitems={link.subitems}
        >
          <Text fontSize="md" fontWeight={500} marginLeft={6}>
            {link.name}
          </Text>
        </NavItem>
      ))}
      <Flex
        flexGrow={1}
        height={20}
        paddingX={4}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Button variant={'link'} onClick={onClose}>Fechar</Button>
      </Flex>
    </Flex>
  );
};
